import { deDE as MuiDE } from '@mui/material/locale'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { deDE as DataGridDE } from '@mui/x-data-grid/locales'
import { deDE as DatePickerDE } from '@mui/x-date-pickers/locales'

export default function MUIThemeProvider({ children }: { children: React.ReactNode }) {
    const theme = createTheme(
        {
            palette: {
                primary: { main: '#00B4A9' },
                secondary: { main: '#E9F9F8' }
            },
            typography: {
                fontFamily: 'Montserrat, sans-serif',
                fontWeightRegular: 400,
                fontWeightMedium: 500,
                fontWeightBold: 700,
                fontWeightLight: 300
            }
        },
        MuiDE,
        DataGridDE,
        DatePickerDE
    )

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
